<script setup lang="ts">
</script>

<template>
  <div class="wrapper">
    <AppHeader />
    <slot />
    <AppFooter />
  </div>
</template>
